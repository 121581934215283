import {Component, DestroyRef, inject, OnInit, output} from '@angular/core';
import {NotificationsDataService} from '../../api/services/notifications.data-service';
import {ENotificationActionType, ENotificationStatus, ENotificationType, NotificationModel} from '../../models/api/notification.model';
import {map} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {INotificationActionEvent, NotificationItemComponent} from './notification-item/notification-item.component';
import {ARLogger} from '@relayter/core';
import {AdvancedFiltersDataService} from '../../api/services/advanced-filters.data-service';
import {DataFilterModel} from '../../models/ui/data-filter.model';
import {EDataFieldTypes} from '../../app.enums';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {OverlayModule} from '@angular/cdk/overlay';
import {RDModule} from '@relayter/rubber-duck';
import {AsyncPipe, NgTemplateOutlet, TitleCasePipe} from '@angular/common';
import {ComponentsModule} from '../../components/components.module';

interface INotificationData {
    today: NotificationModel[];
    yesterday: NotificationModel[];
    older: NotificationModel[];
}

const NOTIFICATION_TYPES: Record<ENotificationType, string> =
    {
        [ENotificationType.JOB]: 'Job update',
        [ENotificationType.JOB_DOWNLOAD]: 'Job update with a download link',
        [ENotificationType.WORKFLOW_BETWEEN_STEPS_TRANSITION]: 'Publication item moves between steps',
        [ENotificationType.PUBLICATION_ITEM_UPLOAD]: 'Publication item upload',
        [ENotificationType.WORKFLOW_DELIVERY_LINKS]: 'Publication item links',
        [ENotificationType.NOTE_UPDATE]: 'Note update',
        [ENotificationType.STICKY_COMMENT]: 'Note comments',
        [ENotificationType.STICKY_COMMENT_MENTION]: 'Note comment mention',
        [ENotificationType.DEADLINE_NOTIFICATION_BEFORE]: 'Workflow schedule deadline (reminder)', // reminder
        [ENotificationType.DEADLINE_NOTIFICATION_AFTER]: 'Workflow schedule deadline (overdue)', // warning
    };

@Component({
    selector: 'rl-notifications-sub-menu',
    templateUrl: './notifications-sub-menu.component.html',
    styleUrls: ['./notifications-sub-menu.component.scss'],
    providers: [AdvancedFiltersDataService],
    standalone: true,
    imports: [
        OverlayModule,
        RDModule,
        AsyncPipe,
        TitleCasePipe,
        NgTemplateOutlet,
        ComponentsModule,
        NotificationItemComponent
    ]
})
export class NotificationsSubMenuComponent implements OnInit {
    public closeClicked = output<void>();

    private notificationsDataService = inject(NotificationsDataService);
    private advancedFiltersDataService = inject(AdvancedFiltersDataService);
    private destroyRef = inject(DestroyRef);

    public TAB_ACTIVE = 0;
    public TAB_ARCHIVED = 1;

    public tabBarItems: TabBarItemModel[] = [
        new TabBarItemModel('Active', this.TAB_ACTIVE),
        new TabBarItemModel('Archived', this.TAB_ARCHIVED)
    ];

    public _selectedTab: TabBarItemModel;
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            this._selectedTab = tab;
        }
    }

    public selectedNotificationId: string;
    public selectedArchivedNotificationId: string;
    public readonly ENotificationStatus = ENotificationStatus;

    public dataFilters: DataFilterModel[] = [];
    public filterValues: Record<string, any>;
    private typeFilter = (notification, filterValues) => {
        if (!filterValues?.type || !filterValues.type.length) return true;

        return filterValues.type.includes(notification.type);
    };

    public sortedNotifications$: Observable<INotificationData> = combineLatest([
        this.advancedFiltersDataService.getFilterValues(),
        this.notificationsDataService.notifications$
    ]).pipe(
        map(([filterValues, notifications]) => {
            if (!notifications?.length) return null;

            const now = new Date();
            const today = new Date(now.toDateString());
            const yesterday = new Date(today.toDateString());
            yesterday.setDate(yesterday.getDate() - 1);

            return {
                today: notifications.filter((notification) => this.typeFilter(notification, filterValues) && notification.createdAt >= today),
                yesterday: notifications.filter((notification) => this.typeFilter(notification, filterValues) && notification.createdAt < today &&
                    notification.createdAt >= yesterday),
                older: notifications.filter((notification) => this.typeFilter(notification, filterValues) && notification.createdAt < yesterday)
            };
        }),
        takeUntilDestroyed(this.destroyRef)
    );
    public notificationsArchived$: Observable<NotificationModel[]> = combineLatest([
        this.advancedFiltersDataService.getFilterValues(),
        this.notificationsDataService.archivedNotifications$
    ]).pipe(
        map(([filterValues, notifications]) => {
            if (!notifications?.length) return [];

            return notifications.filter(notification => this.typeFilter(notification, filterValues));
        }),
        takeUntilDestroyed(this.destroyRef)
    );

    public ngOnInit(): void {
        this.selectedTab = this.tabBarItems[this.TAB_ACTIVE];

        const items = Object.values(NOTIFICATION_TYPES);
        const dropdownItems = Object.keys(NOTIFICATION_TYPES)
            .map((type: ENotificationType) => new DropdownItem<ENotificationType>(NOTIFICATION_TYPES[type], type));
        this.dataFilters.push(new DataFilterModel('Notification type', 'type', EDataFieldTypes.ENUM, dropdownItems,
            {multiSelect: true, items}));
    }

    public onNotificationClicked(notification: NotificationModel, status?: ENotificationStatus): void {
        switch (status) {
            case ENotificationStatus.READ:
                this.selectedNotificationId = notification._id;
                if (notification.status === ENotificationStatus.UNREAD) {
                    this.notificationsDataService.markAsRead(notification._id);
                }
                break;
            case ENotificationStatus.ARCHIVED:
                this.selectedNotificationId = null;
                if (notification.status !== ENotificationStatus.ARCHIVED) {
                    this.notificationsDataService.markAsArchived(notification._id);
                }
                break;
            default:
                this.selectedNotificationId = notification._id;
                break;
        }
    }

    public onNotificationActionClicked(event: INotificationActionEvent): void {
        switch (event.action.type) {
            case ENotificationActionType.NAVIGATE:
                window.open(event.action.url, '_blank');
                break;
            case ENotificationActionType.DOWNLOAD:
                window.open(event.action.url);
                break;
            default:
                ARLogger.error(`Unhandled notification action ${event.action.type} for notification: ${event.notification.title}`);
        }
    }
}
