import {Component, input} from '@angular/core';
import {RLBaseComponent} from '../../../../../components/rl-base-component/rl-base.component';
import {RDModule} from '@relayter/rubber-duck';
import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownItem} from '../../../../../models/ui/dropdown-item.model';
import {IAccountNotificationPreference} from '../../account.component';
import {ENotificationDestination, NotificationDestinationsConfig, NotificationTypesConfig} from '../../../../../models/api/notification.model';

@Component({
    selector: 'notification-preference',
    templateUrl: 'notification-preference.component.html',
    styleUrls: ['notification-preference.component.scss'],
    standalone: true,
    imports: [
        RDModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class NotificationPreferenceComponent extends RLBaseComponent {
    public formGroup = input<FormGroup<IAccountNotificationPreference>>();
    protected readonly NotificationTypesConfig = NotificationTypesConfig;

    public options = Object.keys(ENotificationDestination)
        .map(item => new DropdownItem<ENotificationDestination>(NotificationDestinationsConfig[item], ENotificationDestination[item]));
}
