@if (form) {
    <form [formGroup]="form">
        <div class="general-input">
        <nuc-form-field label="Model">
            <nuc-dropdown formControlName="model"
                          [items]="models"
                          [nullOption]="false"
                          (dataChange)="onModelChange($event)"
                          placeholder="Select a model">
            </nuc-dropdown>
        </nuc-form-field>
        <nuc-form-field label="Action">
            <nuc-dropdown formControlName="action"
                          [items]="actions"
                          [nullOption]="false"
                          (dataChange)="onActionChanged($event)"
                          placeholder="Select how to process the incoming events">
            </nuc-dropdown>
        </nuc-form-field>
        <div class="section-header identifier">
            Identifiers
            <p>You can use this field to identify and take actions on items.</p>
        </div>
            @if (form.controls.masterBriefing) {
                <nuc-form-field>
                    <nuc-checkbox text="Import to Master briefing" formControlName="masterBriefing" (dataChange)="onMasterBriefingChanged($event)"></nuc-checkbox>
                </nuc-form-field>
            }
            @if (form.controls.campaignNamePath) {
                <nuc-form-field label="Campaign name">
                    <nuc-input formControlName="campaignNamePath" placeholder="Path for campaign name"/>
                </nuc-form-field>
            }
            @if (form.controls.campaignStartDatePath && form.controls.action.value?.getValue() === EProducerAction.UPSERT) {
                <nuc-form-field label="Campaign start date">
                    <nuc-input formControlName="campaignStartDatePath" placeholder="Path for campaign start date"/>
                </nuc-form-field>
            }
            @if (form.controls.campaignEndDatePath && form.controls.action.value?.getValue() === EProducerAction.UPSERT) {
                <nuc-form-field label="Campaign end date">
                    <nuc-input formControlName="campaignEndDatePath" placeholder="Path for campaign end date"/>
                </nuc-form-field>
            }
            <nuc-form-field label="Path">
                <nuc-input formControlName="path" placeholder="Path for item identifier"/>
            </nuc-form-field>
            @if (selectedModel()) {
                <nuc-form-field label="Field">
                    <nuc-dropdown formControlName="field"
                                  placeholder="Select an identifier"
                                  [total]="selectedIdentifierFields().length"
                                  [items]="selectedIdentifierFields()"
                                  [searchable]="true"
                                  (requestData)="searchIdentifier.set($event?.search)">
                    </nuc-dropdown>
                </nuc-form-field>
            }
        </div>
        @if (selectedAction()?.getValue() === EProducerAction.UPSERT) {
            <div class="mappings-container">
                <div>
                    <div>
                        <h1>Mappings</h1>
                        <p>Add data fields and map it to their external path from the producer</p>
                    </div>
                </div>
                @for (mapping of form.controls.mappings.controls; track mapping; let index = $index; let isFirst = $first){
                    <div class="mapping-item" [formGroup]="mapping">
                        <nuc-form-field [label]="isFirst ? 'Path' : null">
                            <nuc-input formControlName="path" placeholder="Property to map"/>
                        </nuc-form-field>
                        <nuc-form-field class="property" [label]="isFirst ? 'Property' : null">
                            <property-control class="property"
                                              formControlName="property"
                                              [ruleProperties]="properties()"
                                              [ignoreIndex]="true"
                                              [variants]="variants()"
                                              [variantRequired]="true"/>
                        </nuc-form-field>
                        <nuc-button-secondary [class.first]="isFirst" icon="nucicon_trash_line" (click)="deleteMapping(index)"/>
                    </div>
                }
                <nuc-button-secondary class="actions" text="Add mapping" icon="nucicon_add" (click)="addMapping()"></nuc-button-secondary>
            </div>

            <div class="code-editor-container">
                <div>
                    <div>
                        <h1>Script</h1>
                        <p>Add script to update incoming document before saving the document into Relayter</p>
                    </div>
                </div>

                @if (form.controls.script.value) {
                    <nuc-javascript-code-editor formControlName="script"
                                                [readOnlyLines]="disabledScriptLines"
                                                [autocompleteExtensions]="autocompleteExtensions()"
                                                (lintDiagnostics)="lintDiagnotics.set($event)"/>
                }

                @if (form.controls.script.value) {
                    <nuc-button-secondary class="actions" text="Remove script" icon="nucicon_trash_line" (click)="removeScript()"></nuc-button-secondary>
                } @else {
                    <nuc-button-secondary class="actions" text="Add script" icon="nucicon_add" (click)="addScript()"></nuc-button-secondary>
                }
            </div>
        }
    </form>
}
@if (loading) {
    <rl-loading-indicator></rl-loading-indicator>
}
