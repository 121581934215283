<div class="header">
    <div class="filter">
        <h1 class="title">Notification Centre</h1>
        <rl-data-filter [filters]="dataFilters"/>
    </div>
    <nuc-button-secondary icon="nucicon_close" (click)="closeClicked.emit()"></nuc-button-secondary>
</div>

<nuc-tab-bar [tabs]="tabBarItems" [(activeTab)]="selectedTab"></nuc-tab-bar>

<div class="content">
    @switch (selectedTab.index) {
        @case (TAB_ACTIVE) {
            @if (sortedNotifications$ | async; as notifications) {
                @if (notifications.today.length||notifications.yesterday.length||notifications.older.length) {
                    @for (property of ['today', 'yesterday', 'older']; track $index) {
                        @for (notification of notifications[property]; track notification._id; let first = $first) {
                            @if (first) {
                                <p class="section-header">{{property | titlecase}}</p>
                            }
                            <rl-notification-item (click)="onNotificationClicked(notification, ENotificationStatus.READ)"
                                                  (archiveButtonClicked)="onNotificationClicked(notification, ENotificationStatus.ARCHIVED)"
                                                  (actionClicked)="onNotificationActionClicked($event)"
                                                  [notification]="notification"
                                                  [showFullDate]="property === 'older'"
                                                  [selected]="notification._id === selectedNotificationId">
                            </rl-notification-item>
                        }
                    }
                } @else {
                    <ng-container [ngTemplateOutlet]="emptyState"></ng-container>
                }
            } @else {
                <ng-container [ngTemplateOutlet]="loading"></ng-container>
            }
        }
        @case (TAB_ARCHIVED) {
            @if (notificationsArchived$ | async; as notifications) {
                @if (notifications.length > 0) {
                    @for (notification of notifications; track notification._id) {
                        <rl-notification-item (click)="selectedArchivedNotificationId = notification._id"
                                              (actionClicked)="onNotificationActionClicked($event)"
                                              [notification]="notification"
                                              [selected]="notification._id === selectedArchivedNotificationId"
                                              [showFullDate]="true">
                        </rl-notification-item>
                    }
                } @else {
                    <ng-container [ngTemplateOutlet]="emptyState"></ng-container>
                }
            } @else {
                <ng-container [ngTemplateOutlet]="loading"></ng-container>
            }
        }
    }
</div>

<ng-template #loading>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
<ng-template #emptyState>
    <nuc-empty-state-component
        [small]="true"
        imageUrl="assets/images/empty_states/notifications.svg"
        title="It's Quiet... Too Quiet"
        subtitle="There are no notification messages.">
    </nuc-empty-state-component>
</ng-template>
